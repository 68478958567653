<template>
  <qlHeader bgColor="#ffffff" theme="white">
    <div slot="logo" class="logo">
      <img :src="logo" />
      <p class="T4B">{{ platformName }}</p>
    </div>
    <template slot="user">
      <qlButton type="btn-ghost-primary" @click="handleBack">返回</qlButton>
      <qlButton type="btn-ghost-danger" @click="handleLogout">退出登录</qlButton>
    </template>
  </qlHeader>
</template>

<script>
import { mapState } from 'vuex';
import logo from '@images/components/header/logo.png';
import qlHeader from '@/components/header';
import qlButton from '@/components/button/index.vue';
// utils
import { getHostUrlPath } from '@/utils/common';

export default {
  components: {
    qlHeader,
    qlButton,
  },
  props: {
    backRouterPath: String,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState({
      platformName: (state) => state.common.platformName,
    }),
  },
  methods: {
    handleBack() {
      if (this.$route.query.redirect) {
        location.href = decodeURIComponent(this.$route.query.redirect);
      } else {
        this.$router.push(this.backRouterPath || '/');
      }
    },
    handleLogout() {
      this.$store.dispatch('user/logout').then(() => {
        location.replace(getHostUrlPath('/login'));
      });
    },
  },
};
</script>
