<template>
  <div class="user-id_card">
    <p class="T2B px-32px pt-24px">个人中心</p>
    <div class="px-20px pb-24px text-center">
      <img-upload class="user-avatar relative" @success="handleUpdate">
        <img class="avatar" :src="avatarUrl" @error="avatarUrl = defalutAvatarUrl" />
        <i class="iconfont icon-xiangji user-avatar-hover"></i>
      </img-upload>
      <p class="T4B">{{ user.userName }}</p>
      <p class="T5 text-F2 mg-t-4">教职工号：{{ user.idNumber || '--' }}</p>
    </div>
    <div class="lesson-list">
      <label class="flex-shrink-0 text-F2 T5">当前任教：</label>
      <div class="flex-1">
        <span class="lesson-tag" v-for="(item, index) in user.teachClassNames" :key="index">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import imgUpload from './imgUpload';
import { getFileUrl } from '@/utils/common';

export default {
  components: {
    imgUpload,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      avatarUrl: '',
      defalutAvatarUrl: require('@images/components/header/default_avatar.png'), // 默认头像
      visible: false,
    };
  },
  watch: {
    user(val) {
      this.getAvatarUrl(val.photo);
    },
  },
  methods: {
    handleUpdate(url) {
      this.getAvatarUrl(url);
      this.$store.dispatch('user/getUserInfo');
    },
    getAvatarUrl(url) {
      this.avatarUrl = getFileUrl(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-id_card {
  width: 300px;
  height: auto;
  background: linear-gradient(180deg, #effffb 0%, #ffffff 120px);
  border-radius: 8px;

  .user-avatar {
    width: 80px;
    height: 80px;
    margin: 44px auto 24px;
    border-radius: 40px;
    overflow: hidden;
    cursor: pointer;

    &-hover {
      @apply text-A10 text-24;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 80px;
      background: rgba(0, 0, 0, 0.4);
      visibility: hidden;
    }
    &:hover .user-avatar-hover {
      visibility: visible;
    }
  }

  .btn-ghost-secondary {
    @apply text-F1 bg-A10 b-1;
    &:hover,
    &:active {
      @apply text-error border border-error border-solid;
    }
    i {
      font-size: 16px;
      vertical-align: bottom;
    }
  }

  .lesson-list {
    @apply flex py-24px mx-24px border-t border-dashed border-A7;
    .lesson-tag {
      @apply inline-block ml-5px mb-10px px-6px T5 text-C3 bg-C2 rounded-4px;
    }
  }
}
</style>
