<template>
  <div class="verification-code">
    <el-input
      :class="{ 'input-with-prefix': showPrefix }"
      placeholder="验证码"
      name="code"
      v-model.trim="code"
      :maxlength="8"
      @input="inputCode"
      @keyup.native.enter="handleKeyup"
    >
      <i slot="prefix" class="iconfont icon-yanzhengma" v-if="showPrefix"></i>
      <i slot="suffix" class="text-theme cursor-pointer hover:opacity-80" @click="getVerificationCode">{{
        buttonContent
      }}</i>
    </el-input>

    <sliderCaptcha
      :visible.sync="visible"
      url="/serv-manager/sms/captcha"
      :query="captchaQuery"
      @success="captchaSuccess"
      @close="visible = false"
    />
  </div>
</template>

<script>
// components
import { input } from 'element-ui';
import sliderCaptcha from '@/components/sliderCaptcha';

export default {
  name: 'verificationCode',
  components: {
    ElInput: input,
    sliderCaptcha,
  },
  props: {
    // 是否需要先选择学校
    mustSchool: Boolean,
    // 手机号码
    phone: [String, Number],
    // 类型（1-重置密码 2-忘记密码 20-短信验证 21-验证码登录）
    type: [String, Number],
    value: {
      type: String,
      default: '',
    },
    showPrefix: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buttonContent: '获取验证码',
      disabled: false,
      timeSetting: undefined, // 计时器

      visible: false,
    };
  },
  computed: {
    schoolId() {
      return this.$store.state.login.schoolInfo.schoolId || this.$store.state.user.userInfo.schoolId;
    },
    captchaQuery() {
      return `schoolId=${this.schoolId}&mobile=${this.phone}&type=${this.type}`;
    },
    code: {
      get() {
        return this.value.replace(/\s/g, '');
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    // 获取验证码
    getVerificationCode() {
      // 发送验证码倒计时中不可以操作
      if (this.disabled) {
        return;
      }
      // 是否选择了学校
      if (this.mustSchool && !this.schoolId) {
        this.$emit('getError', 'schoolName');
        return;
      }
      // 手机号码格式验证
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$emit('getError', 'phone');
        return;
      }

      this.visible = true;
    },

    // 滑块拼图验证通过
    captchaSuccess() {
      this.visible = false;
      let count = 60;
      countDown(this, count);

      // 60秒倒计时，防止频繁点击
      function countDown(vm, count) {
        vm.disabled = true;
        vm.buttonContent = `已发送 (${count}s)`;
        if (count > 0) {
          vm.timeSetting = setTimeout(() => {
            countDown(vm, count - 1);
          }, 1000);
        } else {
          vm.buttonContent = '重新获取';
          vm.disabled = false;
          clearTimeout(vm.timeSetting);
          vm.timeSetting = undefined;
        }
      }
    },

    // 输入验证码
    inputCode(value) {
      this.$emit('input', value.replace(/\s/g, ''));
    },

    // 键盘 enter 事件
    handleKeyup() {
      this.$emit('enter');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input {
    &.input-with-prefix .el-input__inner {
      padding-left: 50px;
    }
    .el-input__prefix {
      left: 12px;
      i {
        @apply text-F1 text-22;
      }
    }
    .el-input__suffix {
      right: 12px;
    }
  }
}
</style>
