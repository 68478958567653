<template>
  <div class="user">
    <Header :backRouterPath="backRouterPath" />
    <div class="user-main-wrapper" v-loading="loading">
      <div class="user-info">
        <userIdCard :user="user" />
      </div>
      <div class="user-setting">
        <p class="user-setting-title T4B">账号安全</p>
        <userSecurity :user="user" @update="getUserInfo" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 用户页
 */
import Header from './components/header';
import userIdCard from './components/userIdCard';
import userSecurity from './components/userSecurity';
// api
import { getUserInfoForPersonalCenter } from '@/api/user';

export default {
  name: 'user',
  components: {
    Header,
    userIdCard,
    userSecurity,
  },
  data() {
    return {
      loading: false,
      backRouterPath: '',
      user: {
        userName: '',
        sex: '',
        photo: '',
        idNumber: '',
        teachClassNames: [],
        loginName: '',
        wechatNickName: '',
        mobileNo: '',
      },
    };
  },
  methods: {
    getUserInfo() {
      this.loading = true;
      getUserInfoForPersonalCenter()
        .then(({ result }) => {
          result.teachClassNames = result.currentTeachClassName?.split(',') || ['无任教'];
          this.user = { ...result };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUserInfo();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backRouterPath = from.fullPath;
    });
  },
};
</script>

<style lang="scss" scoped src="./user.scss"></style>
