<template>
  <el-dialog
    v-on="$listeners"
    v-bind="$attrs"
    custom-class="ql-dialog"
    title="绑定微信"
    width="450px"
    :close-on-press-escape="false"
    @open="updateQrcode"
  >
    <div class="dialog-body_container">
      <div class="wx-qrcode" v-show="!isError" v-loading="loading">
        <iframe :src="qrcodeUrl" frameborder="0"></iframe>
        <p class="mg-t-10 T4">打开微信扫一扫</p>
      </div>
      <div class="wx-qrcode wx-qrcode-err" v-show="isError">
        <img class="pd-t-22" src="@images/components/login/code_error.png" />
        <p class="mg-t-16 mg-b-12 T4 text-error">二维码加载失败</p>
      </div>
      <el-button type="text" icon="iconfont icon-shuaxin" @click="updateQrcode"> 刷新二维码</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Button } from 'element-ui';
// api
import { getQrConnectCloud } from '@/api/common';
import { wechatBindUserServer } from '@/api/user';
// utils
import { getHostUrlPath } from '@/utils/common';

const SUCCESS_STATUS = 0; // 绑定成功
const WECAHT_BINDED_ACCOUNT = 999996; // 该微信已绑定其他账号（重复绑定）
const WECHAT_BIND_ERR = 999999; // 微信绑定异常

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  data() {
    return {
      qrcodeUrl: '',
      loading: false,
      isError: false,
    };
  },
  computed: {
    schoolId() {
      return this.$store.getters.schoolId;
    },
  },

  methods: {
    initQrCode() {
      if (this.qrcodeUrl) return;
      this.isError = false;
      this.loading = true;
      getQrConnectCloud({
        schoolId: this.schoolId,
        returnType: 1,
        deviceType: 3,
        productType: 1,
        schoolHost: getHostUrlPath('/user'),
        token: this.$store.getters.token,
      })
        .then((res) => {
          this.qrcodeUrl = res.result;
        })
        .finally(() => {
          setTimeout(() => {
            if (!this.qrcodeUrl) this.isError = true;
            this.loading = false;
          }, 500);
        });
    },

    updateQrcode() {
      this.qrcodeUrl = '';
      this.initQrCode();
    },

    scanQrCodeCallback() {
      const h = this.$createElement;
      const { status, wechatCode } = this.$route.query;
      switch (Number(status)) {
        case SUCCESS_STATUS:
          // 绑定成功
          this.$emit('success');
          break;
        case WECAHT_BINDED_ACCOUNT:
          // 重复绑定
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-13' }, '此微信已绑定其他账号'),
              h('p', { class: 'T5-2' }, '是否继续绑定新账号？绑定新账号后扫码登录将进入新账号'),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle',
            showClose: false,
            showCancelButton: true,
            confirmButtonText: '继续绑定',
            confirmButtonClass: 'btn-ghost-danger',
          })
            .then(() => {
              this.wechatBindUserRequest(wechatCode);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              });
            });
          break;
        case WECHAT_BIND_ERR:
          // 绑定异常
          this.$emit('error');
          setTimeout(() => {
            this.$message.warning('绑定失败，请刷新二维码重试');
          });
          break;
      }
    },

    // 微信绑定请求
    wechatBindUserRequest(code) {
      wechatBindUserServer({
        code,
        schoolId: this.schoolId,
        deviceType: 3,
        productType: 1,
        replaceBind: 1, // 是否强制更改绑定: 1-是 2-否
      }).then(() => {
        this.$message.success('绑定成功！');
        this.$emit('success');
      });
    },
  },

  mounted() {
    let { token, status } = this.$route.query;
    if (token) {
      this.$message.success('绑定成功');
    } else if (status) {
      this.scanQrCodeCallback();
    } else {
      return;
    }
    this.$router.replace('/user');
  },
};
</script>

<style lang="scss" scoped>
.dialog-body_container {
  height: 332px;
  text-align: center;
  .wx-qrcode {
    width: 232px;
    margin: 0 auto;
    iframe {
      width: 100%;
      height: 232px;
      background: url('~@images/components/login/code_border.png') no-repeat;
    }
  }
  .wx-qrcode-err img {
    width: 160px;
    margin: 0 auto;
  }
  .el-button {
    width: 94px;
    margin: 0 !important;
  }
}
</style>
