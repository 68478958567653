<template>
  <ql-dialog
    v-on="$listeners"
    v-bind="$attrs"
    custom-class="ql-dialog"
    :title="oldMoblie ? '修改手机号码' : '绑定手机号码'"
    width="558px"
    :destroy-on-close="true"
    :close-on-press-escape="false"
    @closed="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
      <el-form-item label="原手机号" v-if="oldMoblie">
        <el-input placeholder="原手机号" v-model.trim="oldMoblie" disabled></el-input>
      </el-form-item>

      <el-form-item label="新手机号" prop="moblieNo">
        <el-input
          placeholder="请输入手机号"
          v-model.trim="form.moblieNo"
          :maxlength="11"
          clearable
          @input="onPhoneInput"
        ></el-input>
      </el-form-item>

      <el-form-item label="验证码" prop="code">
        <verificationCode
          v-model="form.code"
          :phone="form.phone"
          :type="1"
          :showPrefix="false"
          @getError="checkCodeForm"
          @enter="handleSave"
        />
      </el-form-item>
    </el-form>

    <span slot="footer">
      <ql-button @click="$emit('close')">取消</ql-button>
      <ql-button type="btn-fill-primary" :loading="loading" @click="handleSave">提交</ql-button>
    </span>
  </ql-dialog>
</template>

<script>
import { Form, FormItem, Input } from 'element-ui';
import qlDialog from '@/components/dialog/dialog.vue';
import qlButton from '@/components/button/index.vue';
import verificationCode from '@/components/verificationCode';
// api
import { updateMobileServer } from '@/api/user';
import { checkPhone } from '@/utils/validate';

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    qlDialog,
    qlButton,
    verificationCode,
  },
  props: {
    phone: String,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const checkMsg = checkPhone(value);
      if (checkMsg === true) {
        callback();
      } else {
        callback(new Error(checkMsg));
      }
    };

    return {
      form: {
        moblieNo: '',
        code: '',
      },
      rules: {
        moblieNo: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
      },
      loading: false,
    };
  },

  computed: {
    oldMoblie() {
      return this.phone ? `${this.phone.slice(0, 3)}****${this.phone.slice(7)}` : '';
    },
  },

  methods: {
    handleClose() {
      this.form.moblieNo = '';
      this.form.code = '';
      this.loading = false;
    },

    onPhoneInput(value) {
      this.form.phone = value.replace(/[^0-9]/g, '');
    },

    checkCodeForm() {
      this.$refs.form.validateField('moblieNo');
    },

    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const { moblieNo, code } = this.form;
          updateMobileServer({ moblieNo, code })
            .then(() => {
              this.$emit('success', moblieNo);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
