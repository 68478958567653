<template>
  <el-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :class="['sliderCaptcha', { captcha_shake: errorShake }]"
    custom-class="ql-dialog "
    title="请完成下列验证后继续"
    :close-on-press-escape="false"
    top="25vh"
    width="420px"
    append-to-body
    @open="handleOpen"
  >
    <div class="captcha-box" :style="{ width: width }">
      <div class="captcha_wrap">
        <div class="captcha_widget">
          <div class="captcha_widget_empty T5-2" v-show="!bgImage">加载中...</div>
          <img ref="bgImage" :src="bgImage" v-if="bgImage" @load="onImageLoad" />
          <img
            ref="sliderImage"
            class="captcha_widget_template"
            :src="templateImage"
            :style="`transform: translate(${moveX}px, 0px)`"
            v-if="templateImage"
            @load="onTemplateLoad"
          />
          <div :class="['captcha_result', { captcha_result_success: successResult }]">验证成功</div>
          <div :class="['captcha_result', { captcha_result_error: errorShake }]">请正确拼合图像</div>
        </div>
        <div class="captcha_slider">
          <div class="captcha_slider_track">
            <div class="captcha_slider_tip" v-show="!moving">拖动滑块完成拼图</div>
          </div>
          <div
            :class="['captcha_slider_button', { slider_move: moving }]"
            :style="`transform: translate(${moveX}px, 0px)`"
            @mousedown="slideStart"
          ></div>
        </div>
        <div class="captcha_panel">
          <span class="captcha_panel_update" title="刷新验证" @click="refreshCaptcha">
            <i class="iconfont icon-ic_refresh1"></i>换一换
          </span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import { getSliderCaptcha, requestByJsonType } from '@/api/common';

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  props: {
    url: String,
    query: String,
    width: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      visible: true,
      uuid: null,
      bgImage: '',
      templateImage: '',
      captcha: {},
      trackList: [],

      moving: false,
      moveX: 0,
      errorShake: false,
      successResult: false,
    };
  },
  methods: {
    handleOpen() {
      this.resetCaptcha();
      this.refreshCaptcha();
    },

    refreshCaptcha() {
      getSliderCaptcha().then((res) => {
        const {
          backgroundImage,
          backgroundImageHeight,
          backgroundImageWidth,
          templateImage,
          templateImageHeight,
          templateImageWidth,
        } = res.result.captcha;
        this.bgImage = backgroundImage;
        this.templateImage = templateImage;
        this.captcha = {
          bgImageWidth: backgroundImageWidth,
          bgImageHeight: backgroundImageHeight,
          sliderImageWidth: templateImageWidth,
          sliderImageHeight: templateImageHeight,
          startSlidingTime: new Date(),
          endSlidingTime: '',
        };
        this.trackList = [];
        this.uuid = res.result.uuid;
      });
    },

    onImageLoad() {
      this.captcha.bgImageWidth = this.$refs.bgImage.width;
      this.captcha.bgImageHeight = this.$refs.bgImage.height;
    },
    onTemplateLoad() {
      this.captcha.sliderImageWidth = this.$refs.sliderImage.width;
      this.captcha.sliderImageHeight = this.$refs.sliderImage.height;
    },

    // 开始滑动
    slideStart(event) {
      let startX = event.pageX;
      let startY = event.pageY;
      this.captcha.startX = startX;
      this.captcha.startY = startY;

      const pageX = this.captcha.startX;
      const pageY = this.captcha.startY;
      const startTime = this.captcha.startSlidingTime;
      this.trackList.push({
        x: pageX - startX,
        y: pageY - startY,
        type: 'down',
        t: new Date().getTime() - startTime.getTime(),
      });

      window.addEventListener('mousemove', this.slideMove);
      window.addEventListener('mouseup', this.slideEnd);
      this.moving = true;
    },

    slideMove(event) {
      let pageX = Math.round(event.pageX);
      let pageY = Math.round(event.pageY);
      const startX = this.captcha.startX;
      const startY = this.captcha.startY;
      const startTime = this.captcha.startSlidingTime;
      const end = 288;
      let moveX = pageX - startX;
      const track = {
        x: pageX - startX,
        y: pageY - startY,
        type: 'move',
        t: new Date().getTime() - startTime.getTime(),
      };
      this.trackList.push(track);
      if (moveX < 0) {
        moveX = 0;
      } else if (moveX > end) {
        moveX = end;
      }
      this.moveX = moveX;
    },

    slideEnd(event) {
      window.removeEventListener('mousemove', this.slideMove);
      window.removeEventListener('mouseup', this.slideEnd);

      this.captcha.endSlidingTime = new Date();
      let pageX = Math.round(event.pageX);
      let pageY = Math.round(event.pageY);
      const startX = this.captcha.startX;
      const startY = this.captcha.startY;
      const startTime = this.captcha.startSlidingTime;

      const track = {
        x: pageX - startX,
        y: pageY - startY,
        type: 'up',
        t: new Date().getTime() - startTime.getTime(),
      };
      this.trackList.push(track);

      this.sliderValid();
    },

    sliderValid() {
      let { bgImageWidth, bgImageHeight, sliderImageWidth, sliderImageHeight, startSlidingTime, endSlidingTime } =
        this.captcha;

      requestByJsonType({
        url: `${this.url}?captchaUUID=${this.uuid}&${this.query}`,
        method: 'post',
        data: JSON.stringify({
          bgImageWidth,
          bgImageHeight,
          sliderImageWidth,
          sliderImageHeight,
          startSlidingTime,
          endSlidingTime,
          trackList: this.trackList,
        }),
      })
        .then(() => {
          this.successResult = true;
          this.$emit('success');
        })
        .catch(() => {
          this.errorShake = true;
          setTimeout(() => {
            this.resetCaptcha();
            this.refreshCaptcha();
          }, 500);
        });
    },

    resetCaptcha() {
      this.moving = false;
      this.moveX = 0;
      this.errorShake = false;
      this.successResult = false;
      this.trackList = [];
      this.captcha.startSlidingTime = new Date();
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss">
.sliderCaptcha {
  .el-dialog__title {
    font-weight: 400;
  }
  .el-dialog__body {
    padding: 24px 32px 30px;
  }
  &.captcha_shake .el-dialog {
    animation: captcha_shake 0.1s linear infinite both;
  }
  @keyframes captcha_shake {
    25% {
      margin-left: -6px;
    }
    75% {
      margin-left: 6px;
    }
    100% {
      margin-left: 0;
    }
  }
}
</style>
