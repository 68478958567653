<template>
  <ql-dialog
    v-on="$listeners"
    v-bind="$attrs"
    custom-class="ql-dialog"
    title="修改密码"
    width="558px"
    :close-on-press-escape="false"
    @closed="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
      <el-form-item label="旧密码" prop="oldPwd">
        <el-input placeholder="请输入旧密码" v-model.trim="form.oldPwd" type="password" show-password></el-input>
      </el-form-item>

      <el-form-item label="新密码" prop="newPwd">
        <el-input
          placeholder="8-24位，至少包含数字、字母或特殊字符中两种方式"
          v-model.trim="form.newPwd"
          type="password"
          show-password
          :maxlength="24"
        ></el-input>
      </el-form-item>

      <el-form-item label="确认密码" prop="confirmNewPwd">
        <el-input
          placeholder="8-24位，至少包含数字、字母或特殊字符中两种方式"
          v-model.trim="form.confirmNewPwd"
          type="password"
          show-password
          :maxlength="24"
          @keyup.native.enter="handleSave"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <ql-button @click="$emit('close')">取消</ql-button>
      <ql-button type="btn-fill-primary" :loading="loading" @click="handleSave">提交</ql-button>
    </span>
  </ql-dialog>
</template>

<script>
import { Form, FormItem, Input } from 'element-ui';
import qlDialog from '@/components/dialog/dialog.vue';
import qlButton from '@/components/button/index.vue';
// utils
import { rsaEncryption } from '@/utils/common';
import { checkPassword } from '@/utils/validate';
// api
import { modifyPwdServer } from '@/api/user';

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    qlDialog,
    qlButton,
  },
  data() {
    const validateNewPwd = (rule, value, callback) => {
      const res = checkPassword(value);
      if (res !== true) {
        callback(new Error(res));
        return;
      }
      if (value === this.form.oldPwd) {
        callback(new Error('新密码与旧密码不能相同'));
        return;
      }
      callback();
    };

    const validateConfirmPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'));
        return;
      }
      if (value !== this.form.newPwd) {
        callback(new Error('确认密码与新密码不一致!'));
        return;
      }
      callback();
    };

    return {
      form: {
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: '',
      },
      rules: {
        oldPwd: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        newPwd: [{ required: true, validator: validateNewPwd, trigger: 'blur' }],
        confirmNewPwd: [{ required: true, validator: validateConfirmPwd, trigger: 'blur' }],
      },
      loading: false,
    };
  },
  methods: {
    handleClose() {
      this.$refs.form.resetFields();
      this.loading = false;
    },

    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const { oldPwd, newPwd, confirmNewPwd } = this.form;
          modifyPwdServer({
            oldPwd: rsaEncryption(oldPwd.trim()),
            newPwd: rsaEncryption(newPwd.trim()),
            confirmNewPwd: rsaEncryption(confirmNewPwd.trim()),
          })
            .then(() => {
              this.$message.success('修改成功');
              this.$emit('close');
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
