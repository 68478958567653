<template>
  <div>
    <ul class="user-info-security">
      <li class="security-item">
        <img src="@images/components/user/user.png" />
        <div class="security-body">
          <div class="flex-1">
            <p class="security-body_title">我的账号</p>
            <p class="security-body_info">用户账号：{{ user.loginName }}</p>
          </div>
          <ql-button @click="pwdVisible = true">修改密码</ql-button>
        </div>
      </li>

      <li class="security-item">
        <!-- 已绑定微信 -->
        <template v-if="unionId">
          <img src="@images/components/user/wechat-h.png" />
          <div class="security-body">
            <div class="flex-1">
              <p class="security-body_title">
                关联微信<span class="T5-4 ml-12px"><i class="iconfont icon-icon_chenggong"></i>已绑定</span>
              </p>
              <p class="security-body_info">{{ wechatNickName }}</p>
            </div>
            <ql-button @click="unbindWechat">解绑微信</ql-button>
          </div>
        </template>
        <!-- 未绑定微信 -->
        <template v-else>
          <img src="@images/components/user/wechat.png" />
          <div class="security-body">
            <div class="flex-1">
              <p class="security-body_title">关联微信</p>
              <p class="security-body_info-warn"><i class="iconfont icon-icon_jinggao"></i>未绑定</p>
            </div>
            <ql-button @click="wechatVisible = true">绑定微信</ql-button>
          </div>
        </template>
      </li>

      <li class="security-item">
        <!-- 已绑定手机号 -->
        <template v-if="mobileNo">
          <img src="@images/components/user/phone-h.png" />
          <div class="security-body">
            <div class="flex-1">
              <p class="security-body_title">
                关联手机号码<span class="T5-4 ml-12px"><i class="iconfont icon-icon_chenggong"></i>已绑定</span>
              </p>
              <p class="security-body_info">{{ mobileNo }}</p>
            </div>
            <ql-button @click="phoneVisible = true">修改手机号码</ql-button>
          </div>
        </template>
        <!-- 未绑定手机号 -->
        <template v-else>
          <img src="@images/components/user/phone.png" />
          <div class="security-body">
            <div class="flex-1">
              <p class="security-body_title">关联手机号码</p>
              <p class="security-body_info-warn"><i class="iconfont icon-icon_jinggao"></i>未绑定</p>
            </div>
            <ql-button @click="phoneVisible = true">绑定手机号码</ql-button>
          </div>
        </template>
      </li>
    </ul>

    <changePassword :visible.sync="pwdVisible" @close="pwdVisible = false" />
    <bindPhone :visible.sync="phoneVisible" :phone="mobileNo" @success="updatePhone" @close="phoneVisible = false" />
    <bindWechat :visible.sync="wechatVisible" @success="updateUser" @error="bindWechatError" />
  </div>
</template>

<script>
/**
 * 用户页
 */
import qlButton from '@/components/button/index.vue';
import changePassword from './changePassword';
import bindPhone from './bindPhone';
import bindWechat from './bindWechat';
// api
import { wechatUnbindUserServer } from '@/api/user';

export default {
  components: {
    qlButton,
    changePassword,
    bindPhone,
    bindWechat,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      mobileNo: '',
      wechatNickName: '',
      unionId: '', // 微信unionId，为空说明未绑定
      pwdVisible: false,
      phoneVisible: false,
      wechatVisible: false,
    };
  },
  watch: {
    user(val) {
      this.mobileNo = val.mobileNo;
      this.wechatNickName = val.wechatNickName;
      this.unionId = val.unionId;
    },
  },
  methods: {
    updatePhone(phone) {
      this.mobileNo = phone;
      this.phoneVisible = false;
    },

    updateUser() {
      this.$emit('update');
    },

    // 解绑微信
    unbindWechat() {
      const h = this.$createElement;
      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, '确认要解绑微信吗？'),
          h('p', { class: 'T5-2' }, '解绑微信后，将无法使用微信扫码快捷登录。'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '解绑',
        confirmButtonClass: 'btn-ghost-danger',
      })
        .then(() => {
          wechatUnbindUserServer().then(() => {
            this.wechatNickName = '';
            this.unionId = '';
            this.$message.success('解绑成功');
          });
        })
        .catch(() => {});
    },

    bindWechatError() {
      this.wechatVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.security-item {
  @apply flex mb-20px;
  & > img {
    @apply w-54px h-54px mr-20px;
  }
  .security-body {
    @apply flex flex-1 items-center pb-18px border-b border-dashed border-A7;
    .iconfont {
      @apply mr-4px align-bottom;
    }
  }
  .security-body_title {
    @apply T4B mt-2px;
  }
  .security-body_info {
    @apply T5 text-F3 mt-4px;
  }
  .security-body_info-warn {
    @apply T5 text-warn mt-4px;
  }
}
</style>
